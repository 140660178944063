// Wizard Navigation
// --------------------------------
@mixin arrow($color) {
    width: 0px;
    height: 0px;
    border-top: $arrow-size inset transparent;
    border-bottom: $arrow-size inset transparent;
    border-left: $arrow-size solid $color;
    position: absolute;
    content: "";
    top: 0;
}

@mixin arrow-variant($color, $background) {
    @include button-variant($color, $background, $background);
    &:before {
        border-right-color: $background;
    }
    &:after {
        border-left-color: $background;
    }
    &:hover {
        &:before {
            border-right-color: darken($background, 10%);
        }
        &:after {
            border-left-color: darken($background, 10%);
        }
    }
}

.nav-wizard {
    margin-top: 5px;
    > li {
        float: left;
        margin-bottom: 5px;

        > a,
        > span {
            position: relative;
            padding: 5px;
        }

        &:not(:first-child) > a,
        &:not(:first-child) > span {
            padding-left: 2 * $arrow-size - $arrow-sep;
            &:before {
                @include arrow(#fff);
                left: 0;
            }
        }
        &:not(:last-child) > a,
        &:not(:last-child) > span {
            margin-right: $arrow-sep;
            &:after {
                @include arrow($btn-default-border);
                right: -$arrow-size;
                z-index: 2;
            }
        }
        &:first-child > a,
        &:first-child > span {
            border-top-left-radius: $nav-pills-border-radius;
            border-bottom-left-radius: $nav-pills-border-radius;
        }
        &:last-child > a,
        &:last-child > span {
            border-top-right-radius: $nav-pills-border-radius;
            border-bottom-right-radius: $nav-pills-border-radius;
        }

        & a,
        & span {
            @include arrow-variant($btn-default-color, $btn-default-border);
        }

        &.done a,
        &.done span {
            @include arrow-variant($btn-success-color, $btn-success-bg);
        }

        &.active a,
        &.active span {
            @include arrow-variant($btn-primary-color, $btn-primary-bg);
        }
    }
}
