// Participation
// -----------------------------
.participation-row {
    @include make-row();
    background-color: $panel-bg;
    border: 1px solid $panel-default-border;
    margin: 5px 0;
    padding: 6px 0;

    .participation-details {
        @include make-sm-column(11);
    }

    .participation-sidebar {
        @include make-sm-column(1);
        text-align: right;
    }
}

// Campaign
// -----------------------------
.campaign-item {
    border: 1px solid $panel-default-border;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    margin: 0 0 20px 0;
    padding: 10px 20px;
}

.campaign-list-row {
    @include make-row();

    .description {
        @include make-md-column(7);
    }

    .meta-information {
        @include make-md-column(5);

        dl {
            dt {
                width: 40%;
            }
            dd {
                margin-left: 45%;
            }
        }
    }
}

// Step
// -----------------------------
.step-item {
    @include make-row();
    margin: 5px 0;
    background: $panel-bg;
    border: 1px solid $panel-default-border;

    .step-sidebar {
        @include make-xs-column(1);
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: right;
    }
}
