// Wrap read more
// --------------------------------
.read-more-wrapper {
    *[data-toggle="read-more"] {
        color: $text-muted;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &.show *[data-toggle="read-more"] {
        display: block;
        position: sticky;
        bottom: 0;
        margin: 0;
        background: linear-gradient(
            to bottom,
            transparentize($panel-bg, 1) 0,
            transparentize($panel-bg, 0.5) 10%,
            transparentize($panel-bg, 0.25) 20%,
            transparentize($panel-bg, 0.75) 30%,
            transparentize($panel-bg, 0) 50%,
            transparentize($panel-bg, 0) 100%
        );
    }

    .read-more,
    .read-less {
        padding-bottom: 2rem;
    }
    .read-less {
        padding-top: 3rem;
        padding-bottom: 1rem;
    }
}

.read-more-wrapper-initialized {
    .text {
        overflow: hidden;
        transition: height 1s ease-in-out;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 70%;
            right: 0;
            width: 100%;
            height: 30%;
            background: linear-gradient(
                to bottom,
                transparentize($panel-bg, 1) 0,
                transparentize($panel-bg, 1) 40%,
                transparentize($panel-bg, 0.5) 60%,
                transparentize($panel-bg, 0.25) 90%,
                transparentize($panel-bg, 0) 100%
            );
            pointer-events: none;
        }
    }

    &.show .text {
        transition: height 1s ease-in-out;

        &:after {
            width: 0;
            height: 0;
            background: none;
        }
    }
}
