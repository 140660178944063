$color-gold: #fdbc4b;
$pc-blue: #008fc4;
$proclima-blue: #5899cc;

$color-white: #fcfcfc;
$color-red: #e74c3c;
$color-yellow: #fdbc4b;
$color-brown-light: #c9ce3b;
$color-orange: #f67400;
$color-green: #2ecc71;
$color-blue: #3daee9; //#3498db;
$color-blue-dark: #3498db;
$color-purple: #9b59b6;

$font-size-base: 12.5px;

$border-radius-base: 0;
$border-radius-large: 0;
$border-radius-small: 0;

$grid-float-breakpoint: 992px;
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);

$navbar-height: 40px;
$navbar-default-color: #11222f;
$navbar-default-bg: $pc-blue;
$navbar-default-link-color: $navbar-default-color;
$navbar-default-link-hover-color: lighten($navbar-default-color, 25);

$thumbnail-border-radius: 6px;

$arrow-size: 14px;
$arrow-sep: 4px;

$panel-body-padding: 10px 10px;
$panel-heading-padding: 8px 10px;
