.process-list-item,
.process-list-empty {
    border: 1px solid $panel-default-border;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    background-color: $panel-bg;
    padding-top: 0;
    margin-bottom: 0.75rem;
    margin-right: 0;
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
}

.process-list-empty {
    padding-top: 1em;
    padding-bottom: 1em;
}

.btn-current-url {
    color: white;
}

.process-list {
    > h1,
    > h2 {
        margin-left: 15px;
    }
}

.process-detail {
    border: 1px solid $panel-default-border;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    background-color: $panel-bg;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0.75rem;
    margin-top: 0.75rem;
    padding-top: 1em;
}
