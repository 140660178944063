// Events

.event-list-actions {
    padding-bottom: 1rem;
    .dropdown-menu li a {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    @media (max-width: $screen-sm-max) {
        & > * {
            margin-bottom: 0.5rem;
        }
    }
}

.event-item {
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid darken($gray-lighter, 5%);
    background-color: $panel-bg;
}

.history-item.limited-access {
    > .history-note {
        > .inner {
            background: repeating-linear-gradient(
                45deg,
                #f9dacd,
                #f9dacd 5px,
                #f9fafb 5px,
                #f9fafb 6px
            );
            background-size: auto;

            > .history-meta,
            > .history-body {
                background-color: $panel-bg;
                margin-right: 0;
                margin-left: 0;
            }

            > .history-meta {
                margin-top: 5px;
            }

            > .history-body {
                margin-bottom: 5px;
            }
        }
    }
}

.event-info {
    @include make-row(5px);
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #eeeeee;
}

.event-image {
    @include make-xs-column(1, 5px);
    width: 40px;
}

.event-name-and-date {
    @include make-xs-column(1, 5px);
    width: 80%;
}

.event-name,
.event-date {
    display: block;
}

.event-date {
    @extend .text-muted;
}

.event-timelines {
    @include make-xs-column(12);
    display: inline-block;
    width: 100%;
    overflow-y: auto;
}

.event-timeline {
    margin-top: 5px;
    padding-top: 2px;
    border-top: 1px solid $gray-lighter;
    position: relative;
}
