//
// Page loading indicator
// --------------------------------------------------

.loading-indicator,
.loading-error {
    padding: 40px 0;
    text-align: center;
}

/* Effect Corner indicator */
.page-loading-inidicator-corner {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 300px;
    background: #ed4e6e;
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    -webkit-transform: translateX(100%) translateY(-100%) rotate(45deg);
    transform: translateX(100%) translateY(-100%) rotate(45deg);
    pointer-events: none;
    z-index: -1;
    &.animate {
        z-index: 10000;
        -webkit-transform: translateX(50%) translateY(-50%) rotate(45deg);
        transform: translateX(50%) translateY(-50%) rotate(45deg);
    }
    &::before,
    &::after {
        position: absolute;
        bottom: 30px;
        left: 50%;
        display: block;
        border: 5px solid #fff;
        border-radius: 50%;
        content: "";
    }
    &::before {
        margin-left: -40px;
        width: 80px;
        height: 80px;
        border-right-color: #bb344f;
        border-left-color: #bb344f;
        -webkit-animation: rotation 3s linear infinite;
        animation: rotation 3s linear infinite;
    }
    &::after {
        bottom: 50px;
        margin-left: -20px;
        width: 40px;
        height: 40px;
        border-top-color: #bb344f;
        border-bottom-color: #bb344f;
        -webkit-animation: rotation 1s linear infinite;
        animation: rotation 1s linear infinite;
    }
}

@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Effect YouTube loading */
$loading-effect-time: 3s;
.page-loading-inidicator {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 5px;
    background: #ed4e6e;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    pointer-events: none;
    &::after {
        position: absolute;
        top: 0;
        right: 0;
        width: 100px;
        height: 100%;
        background: #ed4e6e;
        box-shadow:
            0 0 15px #ed4e6e,
            0 0 5px #ed4e6e;
        content: "";
        opacity: 0;
        -webkit-transition:
            opacity 0.2s,
            box-shadow 2s 4s;
        transition:
            opacity 0.2s,
            box-shadow 2s 4s;
        -webkit-transform: rotate(2deg) translateY(-2px);
        transform: rotate(2deg) translateY(-2px);
    }
    &.animate {
        z-index: 10000;
        opacity: 0;
        -webkit-transition:
            -webkit-transform $loading-effect-time ease-in,
            opacity 1s $loading-effect-time;
        transition:
            transform $loading-effect-time ease-in,
            opacity 1s $loading-effect-time;
        -webkit-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
    }
    &.animate::after {
        box-shadow:
            0 0 3px #ed4e6e,
            0 0 1px #ed4e6e;
        opacity: 1;
    }
}
