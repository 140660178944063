// Speech bubbles
// -----------------------------
$speech-bubble-border-width: 1px;
$speech-bubble-arrow-height: 9px;
$speech-bubble-arrow-width: 19px;
$speech-bubble-arrow-offset: 12px;
$speech-bubble-border-color: $panel-default-border;
$speech-bubble-margin-source: $speech-bubble-arrow-width + 5px;
$speech-bubble-margin-target: $speech-bubble-margin-source * 2;
$box-shadow-color: rgba(0, 0, 0, 0.2);

.history-direction-incoming .history-note > div {
    box-shadow: -3px 3px 6px $box-shadow-color;
    margin-right: $speech-bubble-margin-source;
    margin-left: $speech-bubble-margin-target;
    @media (max-width: $screen-sm-max) {
        margin-left: $speech-bubble-margin-source * 1.5;
    }

    &::before {
        $sb-height: $speech-bubble-arrow-height + $speech-bubble-border-width;
        $sb-width: $speech-bubble-arrow-width + $speech-bubble-border-width;
        $sb-offset: $speech-bubble-arrow-offset - $speech-bubble-border-width;
        border-color: transparent $speech-bubble-border-color;
        border-width: $sb-height 0 $sb-height $sb-width;
        bottom: auto;
        left: auto;
        right: -$sb-width;
        top: 11px;
    }

    &::after {
        border-color: transparent $panel-bg;
        border-width: $speech-bubble-arrow-height 0 $speech-bubble-arrow-height
            $speech-bubble-arrow-width;
        bottom: auto;
        left: auto;
        right: -$speech-bubble-arrow-width;
        top: $speech-bubble-arrow-offset;
    }
}

.history-direction-outgoing .history-note > div {
    box-shadow: 3px 3px 6px $box-shadow-color;
    margin-right: $speech-bubble-margin-target;
    margin-left: $speech-bubble-margin-source;
    @media (max-width: $screen-sm-max) {
        margin-right: $speech-bubble-margin-source * 1.5;
    }

    &::before {
        $sb-height: $speech-bubble-arrow-height + $speech-bubble-border-width;
        $sb-width: $speech-bubble-arrow-width + $speech-bubble-border-width;
        $sb-offset: $speech-bubble-arrow-offset - $speech-bubble-border-width;
        border-color: transparent $speech-bubble-border-color;
        border-width: $sb-height $sb-width $sb-height 0;
        bottom: auto;
        left: -$sb-width;
        top: 11px;
    }

    &::after {
        border-color: transparent $panel-bg;
        border-width: $speech-bubble-arrow-height $speech-bubble-arrow-width
            $speech-bubble-arrow-height 0;
        bottom: auto;
        left: -$speech-bubble-arrow-width;
        top: $speech-bubble-arrow-offset;
    }
}

.history-note > div {
    background: $panel-bg none repeat scroll 0 0;
    border: 1px solid $speech-bubble-border-color;
    border-radius: 3px;
    padding: 0 5px;
    position: relative;

    &::before {
        border-color: $speech-bubble-border-color transparent;
        border-style: solid;
        border-width: 20px 20px 0;
        bottom: -20px;
        content: "";
        display: block;
        left: 40px;
        position: absolute;
        width: 0;
    }

    &::after {
        border-color: $panel-bg transparent;
        border-style: solid;
        border-width: 13px 13px 0;
        bottom: -13px;
        content: "";
        display: block;
        left: 47px;
        position: absolute;
        width: 0;
    }
}

// History tab
// -----------------------------
#tab-communication {
    padding-top: 5px;
}

// History item
// -----------------------------
.history-item {
    @include make-row();
    margin-bottom: 15px;

    .history-created-by {
        @include make-sm-column(1, $grid-gutter-width);
        @extend .hidden-xs;
        text-align: right;
        padding-right: 0;
    }

    .history-note {
        @include make-sm-column(10, $grid-gutter-width);
        @include make-xs-column(12, $grid-gutter-width);
        padding: 0;
    }

    .history-contact {
        @include make-sm-column(1, $grid-gutter-width);
        @extend .hidden-xs;
        text-align: left;
        padding-left: 0;
    }

    .history-meta {
        @include make-row();
        color: $text-muted;

        a {
            color: $text-muted;
        }
    }

    .history-meta-created-by {
        @include make-xs-column(4, $grid-gutter-width);
        text-align: left;
    }

    .history-meta-date {
        @include make-xs-column(4, $grid-gutter-width);
        text-align: center;
    }

    .history-meta-contact {
        @include make-xs-column(4, $grid-gutter-width);
        text-align: right;
    }

    .history-body {
        @include make-row();
        position: relative;
    }

    .history-content {
        @include make-xs-column(12, 0);

        hr {
            margin: 0.5rem 0;
        }
    }

    .history-subject {
        @include make-xs-column(10, $grid-gutter-width);
        h4 {
            padding-bottom: 0.5rem;
            border-bottom: solid 1px $gray-lighter;
        }
    }

    .history-actions {
        @include make-xs-column(2, $grid-gutter-width);
        text-align: right;

        .dropdown {
            display: inline-block;
        }
    }

    .history-text {
        @include make-xs-column(12, $grid-gutter-width);
        word-wrap: break-word;
    }
}

.history-text {
    blockquote {
        @extend .text-muted;
        font-size: 1.25rem;
    }
    code {
        @extend .text-muted;
        background: none;
    }
}

.event-modified-container {
    @include make-xs-column(12, $grid-gutter-width);
    .modified-time {
        color: $text-muted;
        font-size: 1.25rem;
    }
}
