@media (max-width: $screen-lg) {
    .nav-tabs {
        border: 1px solid $nav-tabs-border-color;
        padding: 0;
        margin: 0;

        > li {
            float: none;
            margin: 0;
            border: none;

            a {
                background: #ffffff;
                color: $link-color;
                padding: 5px 10px;
                margin: 0;
                border: none;
            }

            &:hover a,
            & a:focus {
                background: $nav-link-hover-bg;
                color: $link-hover-color;
            }

            &.active a,
            &.active:hover a,
            &.active a:focus {
                border: none;
                background: $nav-pills-active-link-hover-bg;
                color: $nav-pills-active-link-hover-color;
            }
        }
    }
}

.panel-tabbed {
    .panel-heading {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        border-bottom: none;

        > .nav-tabs {
            padding-left: 10px; // default panel y-axis padding
            padding-right: 10px; // default panel y-axis padding
            margin-bottom: 0;

            > li > a,
            > li > a:hover,
            > li > a:focus {
                color: inherit;
            }
        }
    }

    // TODO add support for different panel styles
    &.panel-primary > .panel-heading > .nav-tabs {
        > li.active > a {
            color: $text-color;
        }
        > li:not(.active) > a {
            border-color: $panel-primary-border;
        }
        > li:not(.active) > a:hover,
        > li:not(.active) > a:focus {
            background-color: darken($panel-primary-heading-bg, 5%);
            color: $panel-primary-text;
            border-color: transparent;
        }
    }
}
