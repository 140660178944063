@import "colors";
@import "utilities";
@import "loading-indicator";
@import "pagination";
@import "sidebar-right";
@import "login";
@import "documents";
@import "organisations";
@import "campaigns";
@import "communication";
@import "contact_data";
@import "persons";
@import "tasks";
@import "events";
@import "meditation";
@import "inbox";
@import "workflows";
@import "erp_extension";
@import "notifications";

body {
    padding-top: $navbar-height + 10px; // fixed navigation header height
    overflow-y: scroll;
    background-size: 13px, 29px, 37px, 53px;
}

body.popup {
    padding-top: 0; // no navigation header in popups
    .navbar {
        display: none;
    }
}

.navbar-fixed-top {
    padding: 0 20px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.navbar-brand {
    padding: 10px;
}
.navbar-toggle {
    margin-right: 0;
}
.navbar img {
    height: $navbar-height;
}
.navbar-nav > li > a.user-menu {
    padding-top: 0;
    padding-bottom: 0;
}
.user-menu > i {
    font-size: 1rem;
}

.img-rounded {
    border-radius: $thumbnail-border-radius;
}

.labeled-box {
    border: solid 1px $gray-lighter;
    padding: 20px 10px 10px 10px;
    position: relative;
    margin-top: 20px;

    span.labeled-box-label {
        @extend .h4;
        margin: 0;
        padding: 0 5px;
        position: absolute;
        top: -10px;
        left: 5px;
        background: $body-bg;
    }
}

.modal-body {
    @include clearfix;
}

.page-not-found {
    text-align: center;

    h1 {
        font-family: "Comic Sans MS", "TSCu_Comic", sans-serif;
        margin-bottom: 40px;
    }

    video {
        display: block;
        width: 100% !important;
        height: auto !important;
    }
}

.panel-body {
    word-wrap: break-word;
}

.notify-incoming-call {
    text-align: left;
}

.matching-row {
    td {
        .select2-container {
            position: relative;
            z-index: 2;
            float: left;
            width: 100%;
            margin-bottom: 0;
            display: table;
            table-layout: fixed;
        }
    }
}

.search-form {
    margin-bottom: 1rem;
}

//  Charts
// --------------------------------------------------------------------------------------
.c3 .c3-axis .tick text,
.c3 .c3-legend-item text {
    fill: $text-color;
}

.c3-tooltip {
    background-color: $panel-bg;
    tr,
    th,
    td {
        background-color: $panel-bg;
        color: $text-color;
    }
    th {
        background-color: darken($panel-bg, 5%);
    }
}
