$icon-font-path: "~~bootstrap-sass/assets/fonts/bootstrap/";
@import "~bootstrap-sass/assets/stylesheets/bootstrap";

$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome.scss";

@import "legacy/main";

.process-list-item .extra-description {
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 5px;
    margin-bottom: 5px;

    > p:last-child {
        margin-bottom: 0;
    }
}
