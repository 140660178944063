//
// Documents
// --------------------------------------------------

.template-previews {
    max-height: 300px;
    overflow-y: auto;

    .template-item {
        width: 250px;
        display: inline-block;
        margin: 0 10px 20px 10px;

        .template-name {
            font-weight: bold;
        }

        .template-description {
            color: $text-muted;
        }
    }
}

.select-contact-items {
    max-height: 300px;
    overflow-y: auto;

    .contact-item {
        width: 250px;
        display: inline-block;
        margin: 0 10px 20px 10px;

        &.organisation .contact-image {
            width: 50%;
        }

        &.person .contact-image {
            width: 50%;
        }
    }
}
