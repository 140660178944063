.abas-actions {
    margin-bottom: 5px;
}

.organisation-item {
    @include make-row();
    border: 1px solid darken($gray-lighter, 5%);
    margin: 0 0 10px 0;
    padding: 4px;
    background-color: $panel-bg;

    &.organisation-inactive {
        background-color: lighten($brand-danger, 20%);
        @include gradient-striped;
    }

    h2 {
        margin: 0;
        font-size: $font-size-large;
    }

    .item-warnings {
        @include make-sm-column($grid-columns, $grid-gutter-width);
    }

    .organisation-main-data {
        @include make-sm-column($grid-columns, $grid-gutter-width);
        position: relative;
    }

    .organisation-name {
        @include make-sm-column(8);
        padding-left: 0;
    }

    .organisation-sectors {
        color: $text-muted;
    }

    .organisation-meta-data {
        @include make-sm-column(4);
        padding-right: 0;
        text-align: right;

        .actions {
            display: inline-block;

            .dropdown-toggle {
                padding: 1px 5px;
                color: $text-color;
            }
        }
    }

    .organisation-logo {
        @include make-sm-column(2, $grid-gutter-width);
        @extend .hidden-xs;
        max-width: 150px;
        img {
            max-height: 70px;
        }
    }

    .address {
        @include make-sm-column(5, $grid-gutter-width);
    }

    > .contact-data {
        @include make-sm-column(5, $grid-gutter-width);
        @media (max-width: $screen-xs-max) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}

.table-view {
    .organisation-item {
        border: none;
        box-shadow: none;
        margin: 0;
        padding: 0;

        .table {
            margin-bottom: 0;
        }
    }
}

.organisation-row {
    @include make-row();
    position: relative;

    .organisation-name {
        @include make-sm-column(10);
    }

    .organisation-logo {
        @include make-sm-column(2);
        img {
            max-width: 100%;
            max-height: 50px;
        }
    }

    .organisation-address {
        @include make-sm-column(12);
    }
}

.organisation-inactive {
    background-color: lighten($brand-danger, 20%);
    @include gradient-striped;
}

.panel-organisation {
    .panel-heading {
        padding: 5px;
    }
    .panel-body {
        padding: 5px 10px;
    }
}

// Relations
// --------------------------------
.relation-item {
    color: $color-white;
    h3 {
        margin-top: 0;
    }
    a {
        color: $color-white;
    }
}
.parent-relation {
    background-color: $color-blue;
}
.location-relation {
    background-color: $color-gold;
}
.retailer-relation {
    background-color: $color-green;
}
.subcontractor-relation {
    background-color: $color-orange;
}
.planner-relation {
    background-color: $color-brown-light;
}
.partner-relation {
    background-color: $color-purple;
}
