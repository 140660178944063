.person-row {
    @include make-row();
    border: 1px solid darken($gray-lighter, 5%);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    background-color: $panel-bg;
    padding-top: 0;
    margin-bottom: 0.75rem;
    margin-right: 0;
    margin-left: 0;
    position: relative;

    &.inactive {
        background-color: lighten($brand-danger, 20%);
        @include gradient-striped;
    }

    .person-photo {
        @include make-xs-column(2);
        @include make-xs-column-push(10);
        @include make-sm-column-push(0);
        @include make-sm-column(1);
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        img {
            max-height: 5rem;
        }
    }

    .person-details {
        @include make-xs-column(10);
        @include make-xs-column-pull(2);
        @include make-sm-column-pull(0);
        @include make-sm-column(11);
    }

    .person-name {
        font-size: 2rem;
    }
}

.person-data,
.employment-data {
    padding: 0.5rem;
    background-color: darken($panel-bg, 10%);
    min-height: 5rem;
    position: relative;

    .inactive & {
        background: transparent;
    }
    &.inactive {
        background-color: lighten($brand-danger, 20%);
        @include gradient-striped;
    }
    .inactive &.inactive {
        background: transparent;
    }

    &.is_main_contact {
        border: 2px dashed #ffb733;
        padding: 4px;
    }

    &.is_price_and_condition_responsible {
        border: 2px dashed #ffb733;
        padding: 4px;
    }

    .employment-details {
        @include make-sm-column(12);

        dl {
            margin-bottom: 5px;
        }
    }

    .addresses {
        @include make-sm-column(6);
        font-size: 1.2rem;
    }

    .contact-data {
        @include make-sm-column(5);
        font-size: 1.2rem;
    }

    > .row > .actions {
        top: 5px;
        position: absolute;
        right: 10px;

        .dropdown-toggle {
            padding: 1px 5px;
            color: $text-color;
        }
    }
}

.panel-person {
    .panel-heading {
        padding: 5px;
    }
    .panel-body {
        padding: 5px 10px;
    }
}
