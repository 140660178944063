// Tasks
// -----------------------------
.task-card {
    background-color: $panel-bg;
    margin-bottom: 1rem;
    border: 1px solid $panel-default-border;
    box-shadow: 2px 2px 3px $gray-light;

    .task-status {
        height: 1rem;

        &.task-status-NEEDS-ACTION {
            background-color: darken($state-warning-bg, 15%);
        }
        &.task-status-IN-PROCESS {
            background-color: darken($state-info-bg, 15%);
        }
        &.task-status-COMPLETED {
            background-color: darken($state-success-bg, 25%);
        }
        &.task-status-CANCELLED {
            background-color: darken($state-danger-bg, 25%);
        }
    }

    .task-info {
        padding: 0.5rem;
    }
    &.is-overdue .task-info {
        color: $state-danger-text;
        background-color: $state-danger-bg;
    }

    .assigned-group {
        color: $text-muted;
        font-size: $font-size-small;
    }

    .task-contact {
        color: $text-muted;
        font-size: $font-size-small;
    }

    .task-meta {
        @include make-row();
    }

    .task-current-user {
        @include make-xs-column(3);
        padding-right: 0;

        img {
            max-width: 20px;
        }
    }

    .task-start-date {
        display: block;
        color: $text-muted;
        font-size: $font-size-small;
        text-align: right;
        font-style: italic;
        padding-bottom: 1rem;
    }

    .task-due-date {
        @include make-xs-column(9);

        color: $text-muted;
        font-size: $font-size-small;
        text-align: right;
        font-style: italic;
    }
}

.task-list.sidebar .assigned-group {
    display: none;
}

.task-card {
    position: relative;
    max-width: 540px;
}

.task-card:after {
    position: absolute;
    width: 40%;
    max-width: 100px;
    height: 10px;
    content: " ";
    bottom: 6px;
    background: transparent;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.75);
    z-index: -1;
    left: auto;
    right: 2px;
    transform: skew(3deg) rotate(3deg);
}
