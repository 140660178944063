// Contact data
// --------------------------------
.contact-data-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.contact-data-actions {
    text-align: right;
}
