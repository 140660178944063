.full-page-login {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #444;
    color: #ddd;

    .container {
        max-width: 360px;
        padding: 15px;
        margin: 0 auto;
    }

    h4 {
        padding-top: 20px;
    }

    .form-control {
        position: relative;
        height: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 10px;
        font-size: 16px;
    }

    input,
    button {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border: none;
    }
    input:focus,
    button:focus {
        @include box-shadow(inset 0 0 0 rgba(0, 0, 0, 0));
        border: none;
    }

    input[name="username"] {
        margin-bottom: 0;
    }

    input[type="submit"],
    button {
        margin-top: 10px;
    }
}
