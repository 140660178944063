//
// Notifications
// ==========================

// Sweetalert2
// --------------------------
@import "~@sweetalert2/theme-bootstrap-4/bootstrap-4.scss";

.swal2-container {
    .swal2-popup.swal2-toast .swal2-title {
        margin: 1rem;
        font-size: 1.25rem;
    }
}

.swal-toast-success {
    background-color: $alert-success-bg;
    color: $alert-success-text;

    &.swal2-toast {
        background-color: $alert-success-bg;
        color: $alert-success-text;
    }

    &.swal2-toast .swal2-title {
        color: $alert-success-text;
    }
}

.swal-toast-info {
    background-color: $alert-info-bg;
    color: $alert-info-text;

    &.swal2-toast {
        background-color: $alert-info-bg;
        color: $alert-info-text;
    }

    &.swal2-toast .swal2-title {
        color: $alert-info-text;
    }
}

.swal-toast-error {
    background-color: $alert-danger-bg;
    color: $alert-danger-text;

    &.swal2-toast {
        background-color: $alert-danger-bg;
        color: $alert-danger-text;
    }

    &.swal2-toast .swal2-title {
        color: $alert-danger-text;
    }
}
