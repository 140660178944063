// Meditation

#meditation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00180b;
    z-index: 10000;

    .actions {
        color: #ffffff;
        text-align: right;
        padding: 20px;
        .fa {
            font-size: 40px;
            cursor: pointer;
        }
    }

    .image {
        margin: 20px auto;
        width: 800px;
        img {
            max-width: 100%;
        }
    }
}

*[data-toggle="meditation"],
*[data-toggle="meditation-off"] {
    cursor: pointer;
}
