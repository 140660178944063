// Definition lists
// --------------------------------
.dl-horizontal {
    dt {
        clear: left;
        float: left;
        overflow: hidden;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 160px;
    }
    dd {
        margin-left: 180px;
    }
    &.dl-50 {
        dt {
            width: 50px;
        }
        dd {
            margin-left: 70px;
        }
    }
    &.dl-75 {
        dt {
            width: 75px;
        }
        dd {
            margin-left: 95px;
        }
    }

    &.text-left {
        dt {
            text-align: left;
        }
    }
}

.dl-80-20 {
    dt {
        width: 70%;
    }
    dd {
        width: 20%;
        margin-left: 80%;
    }
}

.dl-60-40 {
    dt {
        width: 50%;
    }
    dd {
        width: 40%;
        margin-left: 60%;
    }
}
