@import "_utilities/read-more-wrap";
@import "_utilities/lists";
@import "_utilities/nav-wizard";
@import "_utilities/nav-tabs";

// Panel collapse heading icons
// --------------------------------
.panel-heading [data-toggle="collapse"]:not(.btn):after {
    font-family: "Glyphicons Halflings";
    content: "\e114";
    float: right;
    color: $panel-default-text;
    .panel-primary & {
        color: $panel-primary-text;
    }
}
.panel-heading [data-toggle="collapse"]:not(.btn).collapsed:after {
    content: "\e080";
}
.panel-heading > [data-toggle="collapse"] {
    color: $panel-default-text;
    .panel-primary & {
        color: $panel-primary-text;
    }

    cursor: pointer;
    display: inline-block;
    width: 100%;
    &:hover {
        text-decoration: none;
    }
}

[class$="-item"],
.inline-item {
    position: relative;

    .item-actions {
        display: block;
        position: absolute;
        bottom: -15px;
        left: 0;
        z-index: 10;
    }

    .item-actions,
    .actions,
    .show-on-hover {
        font-size: 1rem;
    }
}

.popover.confirmation {
    min-width: 200px;
}

.object-list {
    .object-list-item:hover {
        background-color: $table-bg-hover;
    }
}

.inline-ajax-delete-item {
    cursor: pointer;
}

form.hide-labels label {
    display: none;
}

form.hide-labels .checkbox label {
    display: inline-block;
}

// Fix textcomplete dropdown z-index
// --------------------------------
.dropdown-menu,
.textcomplete-dropdown {
    z-index: 2500 !important;
}

// Queryset filter
// --------------------------------
.query-builder .group-conditions .btn-primary {
    @extend .btn-default;
    color: $gray-light;
}
.query-builder .group-conditions .btn-primary.active {
    @extend .btn-primary;
    color: $btn-primary-color;
}

// Modal
// --------------------------------
[data-toggle="modal"] {
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
}

// Dropdowns
// --------------------------------
a[data-toggle="dropdown"] {
    color: inherit;
}

// Toggle Links & Buttons
// --------------------------------
*[data-toggle] {
    cursor: pointer;
}

// Buttons
// --------------------------------
.btn.text-left {
    text-align: left;
}
.btn-group-block {
    width: 100%;
}
.btn-group-md-justified {
    @media (min-width: $screen-md-min) {
        display: table;
        width: 100%;
        table-layout: fixed;
        border-collapse: separate;

        .btn {
            display: table-cell;
            float: none;
            width: 1%;
        }
    }
}
.btn-group-lg-justified {
    @media (min-width: $screen-lg-min) {
        display: table;
        width: 100%;
        table-layout: fixed;
        border-collapse: separate;

        .btn {
            display: table-cell;
            float: none;
            width: 1%;
        }
    }
}

// Inline items
// --------------------------------
.remove-inline,
.dropdown-menu > li > a.remove-inline,
.actions.remove {
    color: $state-danger-text;
    cursor: pointer;
}

.inline-list + .btn-block {
    margin-top: 1px;
}

// Font Awesome Icons
// --------------------------------
.fa-stack-bottom-right {
    margin-left: 0.5em;
    margin-top: 0.5em;
}

// Horizontal rules
// --------------------------------
hr.small-margin {
    margin-top: $line-height-computed * 0.5;
    margin-bottom: $line-height-computed * 0.5;
}
hr.no-margin {
    margin-top: 0;
    margin-bottom: 0;
}

// Box borders
// --------------------------------
.border-right {
    border-right: 1px solid $hr-border;
}

// Dropdown
// --------------------------------
.dropdown {
    display: inline-block;
}
*[data-toggle="dropdown"] {
    cursor: pointer;
}
.dropdown-menu li button {
    // reset button styles
    background: none;
    border: none;
    width: 100%;
    text-align: inherit;

    // copy bootstrap styles
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: $line-height-base;
    color: $dropdown-link-color;
    white-space: nowrap; // prevent links from randomly breaking onto new lines
    &:hover,
    &:focus {
        color: $dropdown-link-hover-color;
        text-decoration: none;
        background-color: $dropdown-link-hover-bg;
    }
}

// Select 2
// --------------------------------
.select2-selection--single {
    overflow: hidden;
}

.select2-container {
    width: 100%;
}

.select2-dropdown {
    min-width: 200px;
}

.select2-selection__clear {
    font-size: 2rem;
    line-height: 2rem;
}

.select2-container .select2-selection {
    border-width: 1px;
    border-style: solid;
    border-radius: $input-border-radius;
    border-color: $input-border;
}
.form-group.has-success .select2-container .select2-selection {
    border-color: $state-success-text;
}
.form-group.has-warning .select2-container .select2-selection {
    border-color: $state-warning-text;
}
.form-group.has-error .select2-container .select2-selection {
    border-color: $state-danger-text;
}

// Responsive images
// --------------------------------
img {
    max-width: 100%;
    height: auto;
}

// awesome bootstrap checkbox
// --------------------------------
.abc-checkbox input[type="checkbox"]:focus + label::before,
.abc-checkbox input[type="checkbox"]:focus,
.abc-checkbox input[type="radio"]:focus + label::before,
.abc-checkbox input[type="radio"]:focus {
    outline: 0 none transparent !important;
}

// Sizes
// --------------------------------
.min-height-160 {
    min-height: 160px;
}

// Boxes
// --------------------------------
.infobox {
    color: white;
    margin-bottom: 30px;
    padding: 20px;

    > a,
    > p > a {
        color: white;
    }

    > dl {
        margin-bottom: 0;
        a {
            color: white;
        }
    }

    .datepicker {
        color: $text-color;
    }
}

// Colors
// --------------------------------
.color-white {
    color: $color-white;
    &:hover,
    &:focus,
    &:active {
        color: $color-white;
    }
}
.bg-blue {
    background-color: $color-blue;
    color: $color-white;
}
.bg-gold {
    background-color: $color-gold;
}
.bg-yellow {
    background-color: $color-yellow;
}
.bg-green {
    background-color: $color-green;
}
.bg-brown {
    background-color: $color-brown-light;
}
.bg-orange {
    background-color: $color-orange;
}
.bg-red {
    background-color: $color-red;
}
.bg-purple {
    background-color: $color-purple;
}

// Typography
// --------------------------------
.text-mono {
    font-family: $font-family-monospace;
}

a.disabled,
.dropdown-menu > li > a.disabled {
    color: $btn-link-disabled-color;
    text-decoration: none;
    cursor: $cursor-disabled;
    pointer-events: none;
}

// Navigation
// --------------------------------
.nav-tabs {
    margin-bottom: 1rem;
}
