@import "pre-customizations";

$body-bg: #ffffff;
$text-color: #333333;

$navbar-default-color: #11222f;
$navbar-default-bg: #5899cc;

@import "customizations";
@import "app";

body {
    background-color: rgba($color-blue, 0.15);
    background-image: linear-gradient(
            90deg,
            rgba($color-white, 0.07) 50%,
            transparent 50%
        ),
        linear-gradient(90deg, rgba($color-white, 0.13) 50%, transparent 50%),
        linear-gradient(90deg, transparent 50%, rgba($color-white, 0.17) 50%),
        linear-gradient(90deg, transparent 50%, rgba($color-white, 0.19) 50%);
}

@import "~select2/dist/css/select2.min.css";
