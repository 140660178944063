// Inbox Styles
// --------------------------
.inbox-list {
    a {
        display: block;
        color: $text-color;
        border-bottom: 1px solid $gray-lighter;
        margin-bottom: 1rem;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    .nav > li > a {
        padding: 5px 8px;
    }

    span.row {
        display: block;
    }
}

.nav-pills.row {
    li + li {
        margin-left: 0;
    }
}
